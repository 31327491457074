import NavItem from './NavItem';
import { links } from '~/compositions/AppShell/Header/MainNav/data';
import { classList } from '~/utilities/css';

function MainNav() {
	return (
		<nav
			aria-label="Main Navigation"
			className={classList([
				'uic-flex',
				'uic-text-white',
				'uic-text-13',
				'uic-font-proximaNova',
				'uic-font-bold',
				'uic-tracking-wide',
				'uic-h-full',
			])}
		>
			{links.map((link) => (
				<NavItem key={link.label} link={link} />
			))}
		</nav>
	);
}

export { MainNav as default, MainNav };
