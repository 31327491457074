import ClientSwitcher from './ClientSwitcher';
import AppSwitcher from './AppSwitcher';
import ContextNav from './ContextNav';
import MainNav from './MainNav';

function Header() {
	return (
		// Responsible for providing the macro layout for these components
		<header className="uic-flex uic-items-center uic-bg-nero uic-h-[50px] uic-min-w-320">
			<ContextNav />
			<MainNav />

			<div className="uic-ml-auto uic-flex uic-gap-15 uic-items-center">
				<ClientSwitcher />
				<AppSwitcher />
			</div>
		</header>
	);
}

export { Header as default, Header };
