import { Link } from '@remix-run/react';
import { classList } from '~/utilities/css';

type CatchProps = {
	statusCode: number;
	message: string;
};

function Catch({ statusCode, message }: CatchProps) {
	return (
		<div className="uic-h-full uic-grid uic-grid-cols-1 uic-grid-rows-1 uic-bg-snow">
			<div className="uic-place-self-center uic-items-center uic-text-center uic-relative -uic-top-60">
				<h1 className="uic-text-[75px] uic-mb-20">{statusCode}</h1>
				<p className="uic-text-sm uic-mb-20">{statusCode === 401 ? 'It looks like your session has expired! Please try again.' : message}</p>
				{statusCode === 401 && (
					<Link
						to="/destroy-session"
						className={classList([
							'uic-bg-shamrock uic-text-white uic-rounded-md uic-text-center',
							'uic-text-xxs uic-font-semibold',
							'uic-px-10 uic-py-8 uic-w-3/4',
						])}
					>
						Try again
					</Link>
				)}
			</div>
		</div>
	);
}

export { Catch as default, Catch };
