import React from 'react';
import Svg from '~/components/Svg';
import { classList } from '~/utilities/css';

type PublicAppShellProps = {
	children: React.ReactNode;
};

/**
 * This layout is trimmed down and has NO application frame items like navigation. It is meant to nicely center a trimmed
 * down page both vertically an horizontally.
 */
function PublicAppShell({ children }: PublicAppShellProps) {
	return (
		<div className={classList([
			'uic-grid uic-justify-center uic-content-center uic-h-full',
			'uic-bg-sky',
		])}
		>
			<main className="uic-flex uic-flex-col uic-items-center">
				<Svg name="bv-logo" className="uic-fill-white uic-w-[150px] uic-h-[41px] uic-mx-auto uic-mb-20" />
				{children}
			</main>
		</div>
	);
}

export { PublicAppShell as default, PublicAppShell };
