import {
  isRouteErrorResponse,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useMatches,
  useRouteError,
  MetaFunction,
  useRouteLoaderData,
  Link } from
'@remix-run/react';
import { withSentry, captureRemixErrorBoundaryError } from '@sentry/remix';
import { json, LoaderFunctionArgs } from '@remix-run/node';
import type { LinksFunction } from '@remix-run/node';
import { toast, Toaster, ToastBar } from 'react-hot-toast';
import React from 'react';
import fontStyles from './resources/styles/fonts.css';
import tailwindStyles from './resources/styles/tailwind.build.css';
import AppShell, { PublicAppShell } from './compositions/AppShell';
import { commitSession, getOrCreateEmptySession } from './services/sessions.server';
import { AuthProvider } from './context/useAuth';
import Catch from './compositions/Catch';
import ErrorLayout from './compositions/ErrorLayout';
import { SessionClient, SessionUser } from './types/Session';
import { ConfigProvider } from './context/useConfig';
import { PublicConfig, PublicConfigType } from './config/public.server';
import getSessionData from './utilities/getSessionData';
import useToast from './hooks/useToast';
import Icon from './components/Icon';
import { Button } from '~/components/Verity-Ui/Button';

export const links: LinksFunction = () => [
{ rel: 'stylesheet', href: fontStyles },
{ rel: 'stylesheet', href: tailwindStyles }];


export const meta: MetaFunction = () => [{ title: 'BV Console' }];

/**
 * Remember that the `root` route is used for both PUBLIC and AUTH routes! Therefore do not place any "guards" in this
 * file to check for authenticated users, do that in nested routes instead.
 */
export async function loader({ context, request }: LoaderFunctionArgs) {
  const session = await getOrCreateEmptySession(request.headers.get('Cookie'));
  const user = getSessionData<SessionUser>('user', session);
  const client = getSessionData<SessionClient>('client', session);
  const GLOBAL_MESSAGE = getSessionData<string>('GLOBAL_MESSAGE', session);
  const config: PublicConfigType = PublicConfig;
  const { nonce } = context;

  await commitSession(session);

  return json({
    toast: {
      toastedAt: GLOBAL_MESSAGE ? Date.now() : null,
      message: GLOBAL_MESSAGE
    },
    client,
    config,
    nonce,
    user,
    hasSingleClient: session.get('hasSingleClient') ?? false
  });
}

/**
 * Default page export
 *
 * Think of this component as the place where we determine the "context" and "overall" layout of the app. The `Layout`
 * export will automatically wrap this component with "global" template level content.
 */
function App() {
  const {
    client,
    config,
    user,
    hasSingleClient
  } = useLoaderData<typeof loader>();

  const isPublic = useMatches().some(
    (match) => match.id.startsWith('routes/__public')
  );

  const isClients = useMatches().some(
    (match) => match.id.startsWith('routes/clients')
  );

  /**
   * All public routes render here (+1 auth route)
   */
  if (
  isPublic
  // 👀 This is the ONE known use case where we _want_ to display an authenticated user within the public app shell.
  // Selecting a `client` is required to use the app as of today and though it is not required for auth, it is meant
  // to feel like it is a part of the auth flow into the app.
  || isClients && user)
  {
    return (
      <PublicAppShell>
				<Outlet />
			</PublicAppShell>);

  }

  /**
   * Fully bootstrapped application should be rendered here
   */
  return (
    <AuthProvider
      user={user}
      client={client}
      hasSingleClient={hasSingleClient}>

			<ConfigProvider config={config}>
				<AppShell>
					<Outlet />
				</AppShell>
			</ConfigProvider>
		</AuthProvider>);

}

/**
 * Provides default layout for all Remix routes
 * See: https://remix.run/docs/en/main/file-conventions/root#layout-export
 *
 * From the Remix docs:
 * `useLoaderData` is not permitted to be used in `ErrorBoundary` components because it is intended for the happy-path route
 * rendering, and its typings have a built-in assumption that the loader ran successfully and returned something. That
 * assumption doesn't hold in an `ErrorBoundary` because it could have been the `loader` that threw and triggered the boundary!
 * In order to access `loader` data in ErrorBoundary's, you can use `useRouteLoaderData` which accounts for the `loader` data
 * potentially being undefined. Because your `Layout` component is used in both success and error flows, this same restriction
 * holds. If you need to fork logic in your Layout depending on if it was a successful request or not, you can use
 * `useRouteLoaderData('root')` and `useRouteError()`.
 */
export function Layout({ children }: {children: React.ReactNode;}) {
  const rootLoaderData = useRouteLoaderData<typeof loader>('root');
  useToast(rootLoaderData?.toast);

  return (
    <html lang="en">
			<head>
				<meta charSet="utf-8" />
				<meta
          name="viewport"
          content="width=device-width,initial-scale=1" />

				<Meta />
				<Links />

				{/* Necessary to get access to some of our `.env` values in client code. We _almost_ get around this
             because in React land we are able to expose these values to our FE via `useConfig` hook BUT Sentry
          also needs these values and it does not run within that context (it just adds/runs a script based
          on some init code). 🚨 HOWEVER, TAKE NOTE THAT WE DO NOT EXPOSE EVERYTHING IN .ENV BUT RATHER A
          TRIMMED DOWN AND PUBLIC VERSION OF VALUES THAT OKAY TO SHIP TO THE BROWSER (see import file) 🚨!
          */}

				<script
          nonce={rootLoaderData?.nonce}
          /* eslint-disable react/no-danger */
          dangerouslySetInnerHTML={{
            __html: `window.__BVC__ = ${JSON.stringify(rootLoaderData?.config ?? null)}`
          }} />

			</head>

			<body className="uic-font-roboto uic-text-xxs uic-h-[100vh]">
				{children}
				<ScriptContainer nonce={rootLoaderData?.nonce || ''} />
			</body>
		</html>);

}

function ScriptContainer({ nonce }: {nonce: string;}) {
  return (
    <>
			<ScrollRestoration nonce={nonce} />
			<Scripts nonce={nonce} />
			<Toaster
        position="bottom-left"
        containerStyle={{
          top: 70,
          right: 20
        }}>

				{(t) =>
        <ToastBar toast={t} style={{ padding: '0px', maxWidth: '620px', width: '100%' }}>
						{({ icon, message }) =>
          <div className={`uic-bg-${t.type} uic-flex uic-p-20 uic-rounded-md uic-min-h-20 uic-w-full`}>
								<div className="uic-flex uic-items-center uic-justify-center uic-min-h-20">
									{icon}
								</div>
								{t.id.includes('/') ?
            <Link
              to={`${t.id}`}
              onClick={() => toast.dismiss(t.id)}
              className="uic-text-azure uic-font-bold">
              {message}
									</Link> :
            <span className={`uic-font-bold uic-text-${t.type}dark`}>{message}</span>}
								{t.type !== 'loading' && (t.type === 'success' || t.type === 'error') &&
            <Button
              aria-label="Dismiss toast"
              className="uic-rounded-full hover:uic-shadow-md uic-ml-auto"
              variant="text"
              onPress={() => toast.dismiss(t.id)}>
              <Icon name="cross" className={`uic-w-15 uic-h-15 uic-shrink-0 uic-fill-${t.type}dark`} />
									</Button>}

							</div>}

					</ToastBar>}

			</Toaster>
		</>);

}

export function ErrorBoundary() {
  const error = useRouteError();

  captureRemixErrorBoundaryError(error);

  return (
    <>
			{/* eslint-disable-next-line no-nested-ternary */}
			{error instanceof Error ?
      <ErrorLayout error={error} /> :
      isRouteErrorResponse(error) ?
      <Catch statusCode={error.status} message={error.statusText} /> :
      null}
		</>);

}

export default withSentry(App);