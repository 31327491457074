import { NavLink, useLocation } from '@remix-run/react';
import { Menu } from '@headlessui/react';

import {
	isLink,
	isNestedLink,
} from '~/compositions/AppShell/Header/MainNav/data';

import type {
	Link as LinkType,
	NestedLink as NestedLinkType,
} from '~/compositions/AppShell/Header/MainNav/data';

import { classList } from '~/utilities/css';

type NavItemProps = {
	link: LinkType | NestedLinkType;
};

function NavItem({ link }: NavItemProps) {
	if (isLink(link)) {
		return (
			<Link
				label={link.label}
				url={link.url}
				href={link.url}
				local={link.local}
			/>
		);
	}

	if (isNestedLink(link)) {
		return (
			<NestedLink
				label={link.label}
				items={link.items}
				baseUrl={link.baseUrl}
			/>
		);
	}

	return null;
}

function Link({ label, url, local = false }: LinkType) {
	const baseStyles = classList([
		'uic-flex',
		'uic-flex-col',
		'uic-justify-center',
		'uic-items-center',
		'uic-px-15',
		'hover:uic-cursor-pointer',
		'hover:uic-bg-sanjuan',
	]);

	return local ? (
		<NavLink
			to={url}
			className={({ isActive }) => (isActive ? `uic-bg-sanjuan ${baseStyles}` : baseStyles)}
			reloadDocument
			data-testid={`TestId__${label}`}
		>
			{label}
		</NavLink>
	) : (
		<a
			href={url}
			target="_blank"
			rel="noreferrer"
			className={baseStyles}
			data-testid={`TestId__${label}`}
		>
			{label}
		</a>
	);
}

function NestedLink({ label: menuLabel, items, baseUrl = '' }: NestedLinkType) {
	const { pathname } = useLocation();
	// TODO: revisit logic, also check to see if there is a idiomatic way to do this in Remix (outside of <NavLink />)
	// We want to highlight the top-level item for any of the urls that live under `/trademark/*` but we only want to
	// highlight the "Overview" item in the dropdown for, well, the overview page.
	const isActive = baseUrl && pathname.includes(baseUrl);

	return (
		<Menu
			as="div"
			className="uic-h-full uic-flex uic-flex-col uic-justify-center uic-items-center uic-relative"
		>
			{/* Thoughts:
				The current menu in BV Classic depends on "hover" to show the nested nav items. This is not the most
				ideal default because it assumes that hover is always available on all devices (we know that it is not).
				We can provide a better user experience by removing this behavior. If the item does NOT have nested items,
				simply make it a link. If the item HAS nested items and the user "clicks/taps" on the item, the nested
				menu will open. In the latter case, the top-level nav item is moved to the dropdown and should NOT be a
				link.

				TODO: delete this comment once consensus is made in one direction or another
			*/}
			<Menu.Button
				className={classList([
					'uic-text-xs',
					'uic-font-proximaNova',
					'uic-font-bold',
					'uic-tracking-wide',
					'uic-px-15',
					'uic-h-full',
					'hover:uic-cursor-pointer',
					'hover:uic-bg-sanjuan',
				])}
				data-testid="TestId__NAV"
			>
				{menuLabel}
				{isActive ? (
					<div
						aria-hidden="true"
						className={classList([
							'uic-h-4',
							'uic-bg-mercury',
							'uic-absolute',
							'uic-w-full',
							'uic-z-10',
							'uic-bottom-[0]',
							'uic-left-[0]',
						])}
					/>
				) : null}
			</Menu.Button>
			<Menu.Items
				as="ul"
				className={classList([
					'uic-absolute',
					'uic-left-[0]',
					'uic-top-[50px]',
					'uic-pb-4',
					'uic-bg-snow',
					'uic-min-w-[200px]',
					'uic-border-1',
					'uic-shadow-lg',
					'uic-border-[#e0e0e0]',
					'uic-font-thin',
					'uic-text-sky',
					'uic-font-roboto',
				])}
			>
				{items.map(({ url, label, local = false }) => (
					<Menu.Item key={label} as="li">
						{() => (
							local ? (
								<NavLink
									to={url}
									className={(() => (
										`uic-px-15 uic-py-4 uic-inline-block uic-w-full ${isActive ? 'uic-bg-solitude' : ''} hover:uic-bg-white`)
									)()}
									reloadDocument
									data-testid={`TestId__${label}`}
								>
									{label}
								</NavLink>
							) : (
								// NOTE: These are external links as of NOW but as we move more and more functionality
								// into this app, these links should be replaced with Remix's `<Link>` component
								<a
									href={url}
									className="uic-px-15 uic-py-4 uic-inline-block uic-w-full hover:uic-bg-white"
									target="_blank"
									rel="noreferrer"
									data-testid={`TestId__${label}`}
								>
									{label}
								</a>
							)
						)}
					</Menu.Item>
				))}
			</Menu.Items>
		</Menu>
	);
}

export { NavItem as default, NavItem };
