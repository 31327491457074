import { Link } from '@remix-run/react';
import { classList } from '~/utilities/css';
import { report } from '~/utilities/qol';

type ErrorProps = {
	error: Error;
};

function ErrorLayout({ error }: ErrorProps) {
	if (process.env.NODE_ENV !== 'production') {
		report.log(error);
	}

	return (
		<div className="uic-h-full uic-grid uic-grid-cols-1 uic-grid-rows-1 uic-bg-snow">
			<div className="uic-place-self-center uic-items-center uic-text-center uic-relative -uic-top-60">
				<h1 className="uic-headline-md uic-mb-20">
					{(() => {
						if (error.message.includes('Too many requests')) {
							return error.message;
						}

						if (process.env.NODE_ENV !== 'production') {
							return error.message;
						}

						return 'There was a problem :(';
					})()}
				</h1>
				<Link
					to="."
					reloadDocument
					className={classList([
						// TODO: reconcile with `~/styles`, needed to ditch the `flex` styles
						'uic-bg-azure uic-text-white uic-rounded-md',
						'uic-text-xxs uic-font-semibold',
						'uic-px-10 uic-py-[7px] uic-inline-block',
					])}
				>
					Try again
				</Link>
			</div>
		</div>
	);
}

export { ErrorLayout as default, ErrorLayout };
