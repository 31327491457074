import { useRef, useState } from 'react';
import {
	Button, ComboBox, Input, ListBox, ListBoxItem, Popover, ComboBoxProps,
} from 'react-aria-components';
import { useFetcher, useLocation } from '@remix-run/react';
import { Http } from '@bv/console-sdk-node-next';
import Icon from '~/components/Icon';
import { useAuth } from '~/context/useAuth';
import { useConfig } from '~/context/useConfig';
import { classList } from '~/utilities/css';
import { action as clientAction } from '~/routes/clients';
import getFetcherNavigation from '~/utilities/remix/getFetcherNavigation';
import { SessionClient } from '~/types/Session';
import OverlayPortal from '~/components/OverlayPortal/OverlayPortal';
import { AnyString } from '~/types/Qol';

type Client = {
	id: number,
	name: string,
};

type Props<T extends object> = ComboBoxProps<T> & {
	redirectPath?: AnyString<'lastLocation'>,
};

function ClientSwitcher<T extends object>({ redirectPath = '/', ...props }: Props<T>) {
	const AppConfig = useConfig();
	const auth = useAuth();
	const currentClient = auth.client as Pick<SessionClient, 'id' | 'name'>; // The app can't function without a client in the session
	const { hasSingleClient } = auth;

	const [selectedClient, setSelectedClient] = useState(currentClient);
	const [clients, setClients] = useState<Client[] | null>(null);
	const clientsFetcher = useFetcher<typeof clientAction>();
	const fetcherState = getFetcherNavigation(clientsFetcher);

	const [inputValue, setInputValue] = useState('');
	const inputFilter = useRef<HTMLInputElement>(null);

	const [isLoading, setIsLoading] = useState(false);
	const location = useLocation();

	// 👀 Special case to account for the creation of new clients by admins! A "get clients tax" is paid every time a user
	// interacts with the `ClientSwitcher` or at least on most routes. On the `/admin/clients` route this tax is paid every
	// time the `ClientSwitcher` is interacted with. For now this is the best solution we have outside of setting up something
	// like websockets.
	const isClientsAdmin = location.pathname === '/admin/clients';

	const appHttp = new Http({
		baseUrl: AppConfig.APP_URL,
		hasPathPrefix: false,
	});

	const getClients = () => {
		if (isLoading) return;

		if (isClientsAdmin || !clients) {
			setIsLoading(true);
			// Resource route for getting client data
			appHttp.request('clients/resource').then((response) => {
				const { body: clientsList } = response;

				if (response.statusCode === 200) {
					setClients(clientsList as unknown as Client[]);
				}

				setIsLoading(false);
			});
		}
	};

	return hasSingleClient ? (
		<strong className="uic-bg-transparent uic-text-13 uic-font-bold uic-text-white">{currentClient.name}</strong>
	) : (
		<ComboBox
			aria-label="Choose client"
			className="uic-relative"
			onSelectionChange={(value) => {
				if (!value || !clients) return;

				const chosenClient = clients.find((client) => client.id === value) || selectedClient;

				setSelectedClient(chosenClient);

				clientsFetcher.submit({
					client: chosenClient.id,
				}, { method: 'POST', action: `/clients?index&lastLocation=${redirectPath === 'lastLocation' ? location.pathname : redirectPath}` });

				if (inputFilter.current) {
					inputFilter.current.blur();
				}
			}}
			selectedKey={selectedClient.id}
			menuTrigger="focus"
			{...props}
		>
			{({ isOpen }) => (
				<>
					{fetcherState.isProcessing && <OverlayPortal highest solid message="Changing clients..." />}
					<Input
						ref={inputFilter}
						className={classList([
							`${isOpen ? 'uic-text-nero uic-bg-white' : 'uic-text-white uic-bg-nero'}`,
							'focus:uic-ring-0 focus-within:uic-bg-white focus-within:uic-text-nero',
							'uic-bg-transparent uic-text-13 uic-font-bold focus-within:uic-placeholder-nero uic-placeholder-white',
							'uic-py-2 uic-pl-4 uic-pr-30 uic-rounded-md uic-placeholder-ellipsis',
						])}
						placeholder={isOpen ? 'Choose client...' : selectedClient.name}
						value={inputValue}
						onChange={(event) => setInputValue(event.target.value)}
						onFocus={() => getClients()}
						onKeyDown={(event) => {
							if (event.key === 'ArrowDown') {
								setInputValue('');
							}
						}}
						size={(() => {
							// 👀 README
							// Okay, a few notes. So as it turns out calculating the width of an input is a little
							// tricky and there are a number of ways to do it and my solution is probably not the robust.
							// What all of this ↓ madness is doing is calculating the width of the input based on the
							// length of characters. In a perfect world where we were only using fixed width characters
							// this would simply work...but this is not a perfect world (hahaha)! The numbers below are
							// all magic and will break if we change the font-face and probably the font-size, idk. At
							// any rate, if this becomes buggy just output the length as a stop gap, it will probably
							// work well for words with a few characters but poorly for multi-word groups.
							const reduceRatio = 1.20;
							let baseSize = 15;

							if (!currentClient) return baseSize;

							if (currentClient.name.length > 20) {
								baseSize = currentClient.name.length / reduceRatio;

								return baseSize;
							}

							if (currentClient.name.length < 10) return baseSize;

							baseSize = currentClient.name.length;

							return baseSize;
						})()}
					/>

					<Button
						className="uic-absolute uic-right-4 uic-top-1"
					>
						<span className="uic-sr-only">Show client list</span>

						<Icon
							name={!isOpen ? 'chevron-down' : 'chevron-up'}
							aria-hidden="true"
							className={classList([
								'uic-h-20 uic-w-20',
								`${isOpen ? 'uic-text-nero' : 'uic-text-white'}`,
							])}
						/>
					</Button>

					<Popover>
						<ListBox
							className={classList([
								'uic-text-xxs uic-max-h-[250px] uic-overflow-auto',
								'uic-p-8 uic-rounded-md',
								'uic-bg-white uic-shadow-lg',
							])}
							items={clients || []}
						>
							{!clients ? (
								<ListBoxItem
									className="uic-font-quicksand uic-font-semibold uic-text-xxs uic-py-[2px] uic-px-[5px] uic-pointer-events-none uic-min-w-[150px]"
								>One moment...
								</ListBoxItem>
							) : (client) => (
								<ListBoxItem
									className={({ isFocused, isSelected }) => {
										let classes = '';

										if (isSelected) {
											classes = 'uic-font-bold uic-pointer-events-none';
										}

										if (isFocused && !isSelected) {
											classes = 'uic-bg-ash uic-rounded-[3px] uic-cursor-pointer';
										}

										return `uic-py-[2px] uic-px-[5px] ${classes}`;
									}}
									key={client.id}
								>{client.name}
								</ListBoxItem>
							)}
						</ListBox>
					</Popover>
				</>
			)}
		</ComboBox>
	);
}

export { ClientSwitcher as default, ClientSwitcher };
