import { useState } from 'react';
import Svg from '~/components/Svg';
import { wait } from '~/utilities/qol';

type AppLinkProps = {
	icon: 'pz-icon' | 'bv-icon';
	brand: string;
	description?: string;
	current?: boolean;
	href?: string
}

function AppLink({
	icon,
	brand,
	description,
	href,
	current = false,
}: AppLinkProps) {
	return (
		<li className={current ? 'uic-bg-sky-light' : 'hover:uic-bg-concrete'}>
			<a
				href={href}
				target="_blank"
				className="uic-px-20 uic-py-10 uic-flex uic-items-center uic-gap-10"
				rel="noreferrer"
				data-testid="TestId__APPLINK"
			>
				<Svg name={icon} className="uic-w-40 uic-h-40 uic-rounded-md" />
				<div>
					<strong>{ brand }</strong>
					{ description && (
						<p className="uic-mt-2">{ description }</p>
					)}
				</div>
			</a>
		</li>
	);
}

function AppSwitcher() {
	const [isOpen, setOpen] = useState(false);
	const toggleOpen = () => setOpen(!isOpen);

	return (
		<div className="uic-relative">
			<button
				type="button"
				className="uic-px-20 uic-py-15 uic-block hover:uic-bg-sanjuan focus:uic-bg-sanjuan"
				onClick={toggleOpen}
				// Artificial delay is necessary to pause `onBlur` _just_ long enough to run `toggleOpen`
				onBlur={() => wait(() => setOpen(false))}
				data-testid="TestId__SWITCHAPPS"
			>
				<Svg name="appswitch-icon" className="uic-w-20 uic-h-20" />
				<span className="uic-sr-only">Switch apps</span>
			</button>
			{ isOpen && (
				<ul className="uic-bg-white uic-w-[260px] uic-top-[50px] uic-right-0 uic-absolute uic-shadow-md uic-rounded-bl-md">
					<AppLink
						icon="pz-icon"
						brand="Partnerize"
						href="https://console.partnerize.com"
					/>
					<AppLink
						icon="bv-icon"
						brand="Protect"
						description="Powered by BrandVerity"
						current
					/>
				</ul>
			)}
		</div>
	);
}

export { AppSwitcher as default, AppSwitcher };
