import React from 'react';
import Header from './Header';

type AppShellProps = {
	children: React.ReactNode;
};

function AppShell({ children }: AppShellProps) {
	// NOTE: `uic-z-1` and `uic-z-0` create "stacking contexts", this allows us to ALWAYS position things
	// in the <Header /> above the <main /> content area. If you run into an issue with new requirements, account for
	// this this before making new changes...otherwise you will run into issues where items in the nav weirdly
	// interact with items on the page. For more about "stacking contexts" check out MDN.
	return (
		<div className="uic-flex uic-flex-col uic-h-full">
			<div className="uic-relative uic-z-[1]">
				<Header />
			</div>

			<main className="uic-flex-grow uic-relative uic-z-0">{children}</main>
		</div>
	);
}

export { AppShell as default, AppShell };
