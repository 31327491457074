import toast, { ToastOptions } from 'react-hot-toast';
import { useEffect } from 'react';
import Icon from '~/components/Icon';

type ToastData = {
	toastedAt: number | null;
	message: string | null;
};

export default function useToast(toastData?: ToastData) {
	useEffect(() => {
		if (toastData && toastData.message) {
			const toastOptions: ToastOptions = {
				duration: 7_000,
			};

			const failToast = toastData.message.startsWith('FAIL:');
			const doneToast = toastData.message.startsWith('DONE:');
			const doneToastWithLink = toastData.message.includes(':LINK');
			const warningToast = toastData.message.startsWith('WARNING:');
			const infoToast = toastData.message.startsWith('INFO:');

			if (failToast) {
				toast.error(toastData.message.replace('FAIL:', ''), {
					duration: Infinity,
					icon: <Icon name="block" className="uic-w-20 uic-h-20 uic-shrink-0 uic-fill-errordark" />,
				});
			} else if (doneToast) {
				const linkIndex = toastData.message.indexOf(':LINK');
				const link = linkIndex !== -1 ? toastData.message.slice(linkIndex) : toastData.message;

				toast.success(toastData.message.replace('DONE:', '').replace(/:LINK.*/, ''), {
					...(doneToastWithLink && { id: link.replace(':LINK', '') }), // utilizing toast `id` prop for toast link
					duration: doneToastWithLink ? Infinity : toastOptions.duration,
					icon: <Icon name="check" className="uic-w-20 uic-h-20 uic-shrink-0 uic-fill-successdark" />,
				});
			} else if (warningToast) {
				toast(toastData.message.replace('WARNING:', ''), {
					...toastOptions,
					icon: <Icon name="warning" className="uic-w-20 uic-h-20 uic-shrink-0 uic-fill-warningdark" />,
				});
			} else if (infoToast) {
				toast(toastData.message.replace('INFO:', ''), {
					...toastOptions,
					icon: <Icon name="info-with-circle" className="uic-w-20 uic-h-20 uic-shrink-0 uic-fill-infodark" />,
				});
			}
		}
	}, [toastData]);
}
